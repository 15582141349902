import { BaseElement } from '@/Components/Global/DataForm/Classes/BaseElement'

export class DatePickerElement extends BaseElement {
    key: string
    label: string
    value: string | null
    type: string

    constructor(key: string, label: string) {
        super()
        this.key = key
        this.label = label
        this.value = null
        this.type = 'datepickerelement'
    }

    // Establecer solo la fecha sin la hora
    public setDate(date: string) {
        const dateOnly = date.split('T')[0];  // Extraer solo la fecha
        this.value = dateOnly
        return this
    }

    public clearDate() {
        this.value = null
        return this
    }
}
