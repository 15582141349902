// Component's Classes
import { BaseElement } from '@/Components/Global/DataForm/Classes/BaseElement'

// Class Export
export class InputButton extends BaseElement {
	key: string
	label: string
	type: string
	data: { value: string }

	constructor(key: string, label: string) {
		super()
		this.key = key
		this.label = label
		this.type = 'input-button'
		this.data = { value: '' }
		this.setState(this._defaultInputStateValidator)
		this.setType('text')
	}

	private _defaultInputStateValidator(self: any) {
		if (self.data.value === '') return null
		return true
	}

	public setIcon(icon: (string | [string, string])) {
		this.params.button = { content: 'icon', icon }
		return this
	}

	public setRequires(field: string, friendly: string, values: any) {
		this.params.requires = { field, friendly, values }
		return this
	}

	public setType(type: string) {
		this.params.type = type
		return this
	}
}