// Classes
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'

// Dependencies
import Vue from 'vue'

// Component Extend
const DatePicker = Vue.extend({
    name: 'DatePicker',

	props: {
		label: { type: String, default: '' },
		optional: { type: Boolean, default: false }
	},

	data: function() {
		return {
			date: undefined
		}
	},

	mounted: function() {
		this.setDefault()
	},

	methods: {
		_getDateState: function() {
			if (this.date === undefined) return null
			return this.date !== ''
		},

		clearDate: function() {
			this.date = undefined
		},
		
		setDate: function(date: string) {
			this.date = date
		},

		setDefault: function() {
			const date = new Date()
			const parsed = PrimitiveTools.Dates.parseDateString(date.toString(), false, true)
			this.date = parsed ? parsed.date : undefined
		},

		onInput: function() {
			this.$emit('input', this.date)
		}
	}
})

// Exports
export default DatePicker
export type DatePickerRef = InstanceType<typeof DatePicker>
