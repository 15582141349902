// Classes
import { DatePickerElement } from '@/Components/Global/DataForm/Classes/DatePickerElement'
import { InputButton }       from '@/Components/Global/DataForm/Classes/InputButton'
import { InputTextArea }     from '@/Components/Global/DataForm/Classes/InputTextArea'
import { Select }            from '@/Components/Global/DataForm/Classes/Select'

// Constants
import { Component } from '@/Constants/Global/Component'

// Form Export
export default {
	params: {
		currentTab: 'services',
		numColumns: 2,
	},
	tabs: [
		{
			header: {
				key: 'services',
				text: { onInsert: 'Nueva solicitud', onUpdate: 'Actualizar solicitud' }
			},
			columns: [
				new InputTextArea('Description','Descripcion del Servicio').setVisible(Component.Actions.UPDATE).setReadOnly(Component.Actions.UPDATE),
				new InputTextArea('details','Detalle del Servicio').setVisible(Component.Actions.UPDATE).setReadOnly(Component.Actions.UPDATE),
				new Select('technicalName', 'Reasignar Técnico').setVisible(Component.Actions.UPDATE),
				new Select('statusEquipment', 'Estatus Equipo',['Operativo', 'Detenido']).setVisible(Component.Actions.UPDATE),
				new Select('status', 'Estado Solicitud', ['Abierto','Rechazado','En Curso','Terminado']).setVisible(Component.Actions.UPDATE),
				new InputButton('equipment', 'Equipo').setIcon('pen').setReadOnly(Component.Actions.UPDATE),
				new DatePickerElement('dateProgram', 'Fecha Reparación').setVisible(Component.Actions.UPDATE),
				new Select('workingDay', 'Jornada',['Mañana', 'Tarde']).setVisible(Component.Actions.UPDATE)
			]
		}
	]
}