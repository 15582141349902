// ./
import { parseDataResponse } from './response.parser'
import dataformElements      from './dataform.elements'

// Classes
import { JoiManager }     from '@/Classes/Network/JoiManager'
import { ActionField }    from '@/Classes/Records/ActionField'
import { DataParsers }    from '@/Classes/Responses/DataParsers'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader   from '@/Components/Global/BasicHeader/template.vue'
import DataTable     from '@/Components/Global/DataTable/template.vue'
import PopupTable    from '@/Components/Global/PopupTable/template.vue'
import PopupUserForm from '@/Components/Global/PopupUserForm/template.vue'

// Components (Refs)
import { DataFormRef }      from '@/Components/Global/DataForm/component'
import { DataTableRef }     from '@/Components/Global/DataTable/component'
import { PopupUserFormRef } from '@/Components/Global/PopupUserForm/component'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Vuex }      from '@/Constants/Global/Vuex'
import { Module3 }   from '@/Constants/Modules/Module3'

// Dependencies
import VueMixins  from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View30 = VueMixins(MixinBase, MixinComponent, MixinFetch).extend({
	name: VueRouter.Modules.View30.NAME,

	components: {
		BasicHeader,
		DataTable,
		PopupTable,
		PopupUserForm
	},

	data: function() {
		return {
			states: {
				dataFormAction: Component.Actions.INSERT,
				dataTableParser: parseDataResponse,
				documentToUpdate: undefined,
				inputButtonKey: undefined,
				showDataTable: true,
				showPopupUserForm: false,
				tempPermissions: undefined,
				userStorages: []
			}
		}
	},

	mounted: function() {
		this._initDataTable()
		this._initPermissions()
		this._initDataForm()
		this._nestedDataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
		this._nestedDataTable.setSelectable(true)
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_popupUserForm: function(): PopupUserFormRef {
			return this.$refs.popupUserForm as PopupUserFormRef
		},

		_nestedDataTable: function(): DataTableRef {
			return this._popupUserForm._dataForm._popupTable._dataTable as DataTableRef
		}
	},

	methods: {
		_adjustDateToEndOfDay: function(dateString: string) {
			const date = new Date(dateString)
			date.setUTCHours(18, 59, 59, 999)
			return date.toISOString()
		},

		_fetchServices: async function(page: number) {
			const user = Store.getters.getStoredUser
			
			// Realizar la petición al Servidor.
			const _idAdminCompany = user._idAdminCompany
			const params = { _idAdminCompany, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.ServicesRequest.GetServicesAll, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},

		_initDataForm: function() {
			const { _dataForm } = this._popupUserForm
			_dataForm.initialize(dataformElements)
		},

		_initDataTable: async function(page = 1) {
			const { stacked } = this._dataTable.states
			const response = await Store.dispatch('fetchServicesForPage', {  page })
			//const dataTableParser = stacked ? '' : parseDataResponse
			const { fields, items, actions } = this.states.dataTableParser(response.data)
			this._dataTable.setStates<DataTableRef['states']>({ exportDataAsAsync: true, isLocalSearch: false })
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
		},

		_initRoles: async function() {
			// Limpiar el array para evitar duplicidad de opciones.
			const { _dataForm } = this._popupUserForm;
			_dataForm.clearOptions('technicalName');
		
			// Obtener la lista de usuarios desde el endpoint
			const response = await Store.dispatch('fetchUsersForPage', { type: 'Internal', page: 1 });
			const users = response.data;
		
			if (Array.isArray(users)) {
				for (const user of users) {
					// Filtrar solo los usuarios que tengan el rol "Técnico"
					if (user.roleName === "Técnico") {
						// Agregar el usuario al select de 'technicalName'
						_dataForm.addOption('technicalName', { value: user._idUser, text: user.name + ' ' + user.pLastName });
					}
				}
			}
		},

		_initPopupUserFormDataTable: function(storages: Array<any>, stackedOrDefault = false) {
			if (storages.length > 0) {
				// Acción para permitir remover la Ubicación de la Tabla.
				const _actions = [
					new ActionField('actions', 'Eliminar').addItem('delete', 'icon').setIcon('times-circle').setPermission('ACTION_DELETE').setVariant('red')
				]
				
				// Convertir los datos para mostrarlos en el <DataTable>.
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._popupUserForm._dataTable.states.stacked || stackedOrDefault)
				const { fields, items, actions } = SummaryParser(storages, _actions)
				this._popupUserForm._dataTable.setElements(fields, items, actions)
				return
			}
			this._popupUserForm._dataTable.clearData()
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
			this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
		},

		_resolveUsersPath: function(action: number) {
			return action === Component.Actions.UPDATE
				? Server.Routes.Services.UpdateService 
				: Server.Routes.Services.AddService 
		},

		_updateDataForm: async function() {
			const { _dataForm } = this._popupUserForm
			const { documentToUpdate } = this.states
			const dateClean = documentToUpdate.dateProgram.replace(/\s+/g, '')
			const formattedDate = PrimitiveTools.Dates.reverseDate(dateClean?.replace('---', ''))
			const workingDay = documentToUpdate.workingDay?.replace('---', '')

			_dataForm.setValue('Description', documentToUpdate.Description)
			_dataForm.setValue('details', documentToUpdate.details)
			_dataForm.setValue('technicalName', documentToUpdate._idTechnical || null)
			_dataForm.setValue('equipmentName', documentToUpdate.equipmentName)
			_dataForm.setValue('equipmentCode', documentToUpdate.equipmentCode)
			_dataForm.setValue('statusEquipment', documentToUpdate.statusEquipment)
			_dataForm.setValue('status', documentToUpdate.status)
			_dataForm.setValue('workingDay', workingDay || null)
			_dataForm.setValue('dateProgram', formattedDate)
			_dataForm.setValue('equipment', documentToUpdate._idEquipment, '_id')
			_dataForm.setValue('equipment', documentToUpdate.equipmentName, 'value')
		},

		_updatePopupComponents: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Validaciones Opcionales según Casos.
			const { _dataForm } = this._popupUserForm
			if (!preventShowPopupTable) _dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			if (forceRefresh) this._nestedDataTable.clearAll()
			this._nestedDataTable.setFetchingState()

			if (this.states.inputButtonKey === 'equipment') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Selecciona Equipo')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyEquipments')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchEquipmentsForPage', { forceRefresh, page })
				const SummaryParser = DataParsers.Equipments.SummaryParser
				const { fields, items } = SummaryParser(response.data)
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('name', 'asc')
			}
		},

		onClose: function() {
			const { _dataForm } = this._popupUserForm
			this.setStates<View30Ref['states']>({ documentToUpdate: undefined, showPopupUserForm: false, userStorages: [] })
			_dataForm.clearInputs()
		},

		onDataFormSubmit: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { _dataForm }        = this._popupUserForm
			const { action }           = _dataForm.states

			// Realizar validación de los Campos.
			if (!_dataForm.validateStates()) {
				this.showToast('Error de Validación', 'Verifica que ningun campo se encuentre marcado de color rojo.', 'danger')
				return
			}

			// Inputs del DataForm. 
			const _idService          = documentToUpdate?._idService
			const _idTechnical        = _dataForm.getValue('technicalName')
			const dateProgram         = _dataForm.getValue('dateProgram')
			const adjustedDateProgram = this._adjustDateToEndOfDay(dateProgram)
			const workingDay          = _dataForm.getValue('workingDay')
			const status              = _dataForm.getValue('status')
			const statusEquipment     = _dataForm.getValue('statusEquipment')

			// Validación Fecha.
			if (dateProgram === null) {
				this.showToast('Fecha Reparación', 'Se debe especificar una Fecha de Reparación antes de actualizar Solicitud.', 'danger')
				return
			}

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idService,  _idTechnical, dateProgram: adjustedDateProgram, workingDay, status, statusEquipment
			};

			// Validación de los campos de la petición.
			const joiSchema = Module3.M30.JoiSchemas.UpdateService
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al Actualizar la solicitud de servicio', result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = Server.Fetching.Method.PATCH;
			const response = await this.doFetch({ action: fetchAction, path: this._resolveUsersPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				if (action === Component.Actions.UPDATE) {
					this.onDTRefreshButtonClick()
					this.onClose()
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}
				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}
		},

		onDTButtonClick: function(key: string, row: any) {
			this._initRoles()

			if (key === 'edit') {
				this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: Component.Actions.UPDATE })
				this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.UPDATE, documentToUpdate: row.item, showPopupUserForm: true })
				this._updateDataForm()
			}
		},

		onDTExportDataClick: async function(format: string, filterType: AppValues.DataTableExportDataFilterType, filterValue: AppValues.PeriodsChoiceFriendlyTextList) {
			const user = Store.getters.getStoredUser
			const _idAdminCompany = user._idAdminCompany

			const params = {
				_idAdminCompany,
				itemsPerPage: 1,		// 'itemsPerPage' es Requerido en la Petición pero no es considerada.
				page: 1,				// 'page' es Requerido en la Petición pero no es considerada.
				period: filterValue
			}

			// 'Parser' utilizado para procesar la Respuesta de los Datos.
			const { dataTableParser } = this.states

			// Realizar la Petición al servidor.
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.ServicesRequest.GetServicesAll, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body.data
				const { items } = dataTableParser(_response)
				this._dataTable.handleExportDataType(format, items)
			}
		},

		onDTRefreshButtonClick: async function() {
			Store.commit('destroyServices')
			this._initDataTable(1)
		},

		onDTSearchButtonClicked: function(searching: any, page=1) {
			const { searchKey, searchValue } = searching
			const user = Store.getters.getStoredUser
			const _idAdminCompany = user._idAdminCompany

			// Asignar los parametros por pestaña.
				this._dataTable.doInputSearch(Server.Routes.ServicesRequest.GetServicesRequestBySearchFilter,
					this.states.dataTableParser,
					{ 
						_idAdminCompany, 
						searchKey, searchValue,
						itemsPerPage: this._dataTable.itemsPerPage, page, 
					}, 
					(response) =>  response.data.body[0]
				)
		},

		onDTPaginationChanged: async function(page: number) {
			// Realizar la petición hacia el servidor.
			const response = await this._fetchServices( page)
			const data = Array.isArray(response) ? response : response.data
			const { fields, items, actions } = this.states.dataTableParser(data)
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
			Store.commit('storeServicesForPage', { data, page, totalPages: response.totalPages })
		},
		
		onInputButtonClick: function(key: string) {
			this.setStates<View30Ref['states']>({ inputButtonKey: key })
			this._updatePopupComponents(false)
		},

		onPTSelect: function(key: string, currentRow: any) {
			const { item } = currentRow;
			if (key === 'equipment') {
				const { _dataForm } = this._popupUserForm;
				// Actualizar el _dataForm
				_dataForm.setValue(key, item._idEquipment, '_id');
				_dataForm.setValue(key, item.name, 'value');
			}
		},

		onPUFPaginationChanged: function(page: number) {
			this._updatePopupComponents(false, page)
		},

		onPUFRefreshButtonClick: function() {
			this._updatePopupComponents(false, 1, true)
		}
	}
})

// Exports
export default View30
export type View30Ref = InstanceType<typeof View30>