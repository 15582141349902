// Component's Classes
import { BaseElement } from '@/Components/Global/DataForm/Classes/BaseElement'

// Class Export
export class InputBase extends BaseElement implements InputBaseDefinition {
	events: InputBaseEvents
	type: string
	value: string

	constructor() {
		super()
		this.events = {}
		this.type = 'input'
		this.value = ''
	}

	public _defaultInputStateValidator(self: any) {
		if (self?.value === '') return null
		return true
	}

	public _setType(type: string): void {
		this.params.type = type
	}

	public addEvent(eventName: string, func: Function) {
		this.events[eventName] = func
		return this
	}

	public setFormatter(formatter: Function) {
		this.params.formatter = formatter
		return this
	}
}

interface InputBaseDefinition {
	events?: InputBaseEvents
}

interface InputBaseEvents {
	[key: string]: Function
}