import { InputBase } from '@/Components/Global/DataForm/Classes/InputBase'

export class InputTextArea extends InputBase {
    key: string
    label: string
    private _rows: number = 3 // Valor por defecto

    constructor(key: string, label: string) {
        super()
        this.key = key
        this.label = label

        this._setType('textarea')
        this.setState(this._defaultInputTextValidator)
    }

    // Método para establecer el número de filas
    setRows(rows: number): this {
        this._rows = rows
        return this
    }

    // Getter para obtener el número de filas
    getRows(): number {
        return this._rows
    }

    private _defaultInputTextValidator(self: any) {
        let aux = self.value.trim()
        if (aux === '') return null
        if (aux.length < 3 || aux.length > 120) return false
        return true
    }
}
